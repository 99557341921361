const ASpanishHourPage = ({ data }) => {
  const hero = data.aSpanishHourYaml.aSpanishHourHero

  return (
    <Base>
      <PageMetadata page={data.metadata.siteMetadata.aSpanishHourPage} />

      <main id="main-content" tabIndex="-1" className="sans-serif">
        <div className='container mw8 mx-auto'>
          {/* <Hero
            heading={hero.heading}
            alt={hero.alt}
            objPosition={hero.objPosition}
            quote={hero.quote}
            source={hero.source}
            link={hero.link}
          /> */}
          <h1
            className="page-heading mv5"
            dangerouslySetInnerHTML={{ __html: hero.heading }}
          />
          
          <div
            className='
              container
              mw8
              mt-2
            '
          >
            <div
              className="
                w-full
                aspect-ratio 
                aspect-ratio--16x9        
              "
            >
              <iframe 
                className="aspect-ratio--object"
                src="https://www.youtube-nocookie.com/embed/whu65TQBbgg" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              ></iframe>

            </div>
          </div>
          <Quote
            quote='With generous grant awards support from The Canada Council for the Arts, and The BC Arts council.'
            style={{marginTop: `40px`}}
          />

        </div>
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query ASpanishHourQuery {
    metadata: site {
      siteMetadata {
        aSpanishHourPage {
          description
          title
          url
        }
      }
    }
    aSpanishHourYaml {
      aSpanishHourHero {
        heading        
        alt
        objPosition
        quote
        source
        link
      }
    }    
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

// import Bio from '../sections/TeachingBio'
// import RoguesGallery from '../sections/TeachingRogues'
// import Schedule from '../sections/TeachingSchedule'
// import Scholarship from '../sections/TeachingScholarship'

import Base from '../components/Base'
// import Hero from '../components/Hero'
import PageMetadata from '../components/PageMetadata'
import Quote from '../components/Quote'

export default ASpanishHourPage
